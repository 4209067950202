import React from "react";
import { useState, useEffect } from "react";
import CustomButton from "../components/CustomButton";
import JobCard from "../components/JobCard";
import Navbar from "../components/Navbar";
import { appStore, authStore } from "../store";
import Footer from "../components/Footer";
import SellerLogo from "../assets/seller-logo.png";
import { getJob } from "../api";




const AvailableJobs = () => {
  const [jobs, setJobs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const auth = authStore(state => state);
  const app = appStore(state => state);

  const fetchjobData = async (page) => { 
    app.startLoader();
    try {
        const response = await getJob(page);
        setJobs(response);
        auth.setJobs(response);
    } catch (e) {
        console.log(e.message);
    } finally {
        app.stopLoader();
    }
};

useEffect(() => {
    fetchjobData(currentPage);
}, []);

useEffect(() => {
    fetchjobData(currentPage);
}, [currentPage]);

console.log(jobs);

  return (
    <section className="overflow-x-hidden w-full relative">
      <Navbar token={!!auth.user?.token} role={auth.role} />
      <div className="relative mt-[8.5rem] md:mt-[6.5rem] w-full">
        <h2 className="text-2xl font-bold text-center pt-8">Available Jobs</h2>

        {jobs.length === 0 ? (
          <p className="text-center text-gray-500">No jobs available at the moment.</p>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 py-12 px-4">
            {jobs.map(job => (
              <JobCard key={job.id} job={job} />
            ))}
          </div>
        )}
        <Footer />
      </div>
    </section>
  );
};

export default AvailableJobs;
