import { useEffect, useState } from "react";
import { appStore, authStore } from "../../store";
import InputField from "../../components/InputFieldComponents";
import CustomButton from "../../components/CustomButton";
import BuyerPathBreadCrumb from "../../components/BuyerPath";
import AdminNavbar from "../../components/AdminNavbar";
import AdminSideBar from "../../components/AdminSidebar";
import toast from 'react-hot-toast';
import { toastError, toastSuccess } from "../../utils/constants";
import { useNavigate, useLocation } from "react-router-dom";
import LogoSidebar from "../../components/LogoSidebar";
import { adminCreateJob, adminCreateMarket, adminEditMarket } from "../../api";
import pictureImg from '../../assets/product-media-icon.png';
import api from "../../api/server";

const AdminAddJob = () => {
    const auth = authStore(state => state);
    const app = appStore(state => state);
    const navigate = useNavigate();
    const location = useLocation();
    const data = location.state; // Edit or Add new market
    const [isSidebarOpen, setSidebarOpen] = useState(false);
    const toggleSidebar = () => setSidebarOpen(!isSidebarOpen);

    // Form state
    const [jobData, setJobData] = useState({
        jobTitle: data?.jobTitle || '',
        employer: data?.employer || '',
        remuneration: data?.remuneration || '',
        jobType: data?.jobType || '',
        description: data?.description || '',
       
    });

    const [picture, setpicture] = useState({
        file1: null,
        file2: null,
        file3: null,
        file4: null,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setJobData({ ...jobData, [name]: value });
    };

    // Image uploading function
    const uploadImagesCustom = async () => {
        const fileUrls = [];
        for (const key in picture) {
            if (picture[key]) {
                try {
                    const formData = new FormData();
                    formData.append('file', picture[key]);

                    const response = await api.post('/product/uploadimage', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });

                    fileUrls.push(response.data.fileUrl);
                } catch (error) {
                    // console.error('Error uploading file:', error);
                    // toast('Error uploading image', toastError);
                    // return;
                    return fileUrls;
                }
            }
        }
        return fileUrls;
    };

    // Handle image selection
    const handleProfileImageChange = async (e) => {
        const { name } = e.target;
        const file = e.target.files[0];
        if (file) {
            setpicture((prevpicture) => ({
                ...prevpicture,
                [name]: file,
            }));
        }
    };

    // Create market function
    const createMarket = async () => {
        app.startLoader();
        try {
           
            const newjobData = { ...jobData };

            await adminCreateJob(newjobData, { Authorization: `Bearer ${auth.user.token}` });
            toast('Job Created', toastSuccess);
            setJobData({ name: '', location: '', picture: [] });
            setTimeout(() => navigate(-1), 1000);
        } catch (error) {
            toast(error.message || 'Failed to create job', toastError);
            if (error.message === 'jwt expired') auth.logout();
        } finally {
            app.stopLoader();
        }
    };

    // Edit market function
    const editMarket = async () => {
        app.startLoader();
        try {
            if (jobData.name === data.name && jobData.location === data.location && JSON.stringify(jobData.picture) === JSON.stringify(data.picture)) {
                throw new Error("No changes made");
            }
            
            const updatedjobData = { ...jobData};

            await adminEditMarket(updatedjobData, data.id, { Authorization: `Bearer ${auth.user.token}` });
            toast('Market Updated', toastSuccess);
            setTimeout(() => navigate(-1), 1000);
        } catch (error) {
            toast(error.message || 'Failed to update market', toastError);
            if (error.message === 'jwt expired') auth.logout();
        } finally {
            app.stopLoader();
        }
    };

    useEffect(() => {
        app.startLoader();
        setTimeout(() => app.stopLoader(), 3000);
    }, [auth.user]);

    return (
        <section className="overflow-x-hidden h-[100vh] w-full flex relative">
            <LogoSidebar isSidebarOpen={isSidebarOpen} />
            <div className="w-full">
                <AdminNavbar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} token={!!auth.user?.token} />
                <div className="w-full min-h-[calc(100%-5rem)] mt-[2rem] lg:pt-[5rem] lg:px-5 macScreens:px-[2rem] lg:pb-5 macScreens:pb-[5rem] flex flex-col gap-5 macScreens:gap-10">
                    <BuyerPathBreadCrumb home="Job" shop={!!data ? "> Edit Job" : "> Add New Job"} />
                    <div className="flex flex-col gap-5 px-2 windowScreen:px-5">
                        <h1 className="font-semibold text-[#110D06] lg:text-xl macScreens:text-xl">{!!data ? "Edit Job" : "Add New Job"}</h1>
                        <div className="flex flex-col gap-7 w-full px-7 py-12">
                            <InputField label="Job Title" name="jobTitle" value={jobData.jobTitle} placeholder="Enter job title" type="text" onChange={handleChange} required />
                            <InputField label="Description" name="description" value={jobData.description} placeholder="Enter Job Description" type="text" onChange={handleChange} required />
                           
                            <InputField label="Employer" name="employer" value={jobData.employer} placeholder="Enter Employer" type="text" onChange={handleChange} required />
                            <InputField label="Renumeration" name="remuneration" value={jobData.remuneration} placeholder="Enter Renumeration" type="text" onChange={handleChange} required />
                            <InputField label="Job Type" name="jobType" value={jobData.jobType} placeholder="Enter Job Type FullTime/PartTime" type="text" onChange={handleChange} required />
                          
                            <div className="flex flex-col gap-y-10 w-full col-span-2">
                                <div className="input-box justify-end flex gap-1 lg:gap-2 macScreens:gap-2 macScreens:text-[1rem] lg:text-[.8rem] w-full">
                                    <CustomButton buttonText="Cancel" btnClassName="bg-transparent font-semibold text-[#000] text-sm windowScreen:text-lg rounded-[5px]" onClick={() => navigate(-1)} />
                                    <CustomButton buttonText={!!data ? "Edit" : "Add"} btnClassName="bg-[#00753E] text-[#FBFBFB] text-sm windowScreen:text-lg rounded-[5px]" onClick={() => { data ? editMarket() : createMarket(); }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AdminAddJob;
