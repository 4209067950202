import { useEffect, useState } from "react";
import { appStore, authStore } from "../../store";
import LogoHome from "../../components/Logo";
import SidebarNavLink from "../../components/SidebarNavLink";
import SearchBar2 from "../../components/SearchBarComponent2";
import { NavLink, useNavigate } from "react-router-dom";
import Pagination from "../../components/Pagination";
import CustomButton from "../../components/CustomButton";
import MarketDynamicTable from "../../components/MarketDynamicTable"; // Update to your market table component
import AdminNavbar from "../../components/AdminNavbar";
import AdminSideBar from "../../components/AdminSidebar";
import { adminGetAllJobs, adminGetAllMarkets } from "../../api"; // Update the API call to fetch all markets
import toast from 'react-hot-toast';
import { toastSuccess } from "../../utils/constants";
import LogoSidebar from "../../components/LogoSidebar";
import JobDynamicTable from "../../components/JobDynamicTable";

const AdminJob = () => {
    const auth = authStore(state => state);
    const app = appStore(state => state);
    const navigate = useNavigate();
    const [isSidebarOpen, setSidebarOpen] = useState(false);
    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
    };

    const [marketData, setMarketData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const totalPages = Math.ceil(marketData.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = marketData.slice(indexOfFirstItem, indexOfLastItem);

    const fetchData = async () => {
        app.startLoader();
        try {
            const response = await adminGetAllJobs(); // Replace with actual API call for markets
            setMarketData(response);
            console.log(`Markets: ${JSON.stringify(response)}`);
        } catch (e) {
            console.log(e.message);
            if (e.message === 'jwt expired') {
                auth.logout();
            }
        } finally {
            app.stopLoader();
        }
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        app.startLoader();
        fetchData();
        setTimeout(() => {
            app.stopLoader();
        }, 3000);
    }, []);

    return (
        <section className="overflow-x-hidden h-[100vh] w-full flex relative">
            <LogoSidebar isSidebarOpen={isSidebarOpen}/>
            <div className="w-full">
                <AdminNavbar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} token={!!auth.user?.token} />
                <div className="w-full min-h-[calc(100%-5rem)] mt-[2rem] lg:pt-[5rem] lg:px-0 macScreens:px-[2rem] lg:pb-5 macScreens:pb-[0rem] flex flex-col gap-10">
                    <div className="flex flex-col windowScreen:gap-10 gap-5 px-5">
                        <h1 className="font-semibold text-[#110D06] lg:text-xl macScreens:text-xl">Jobs</h1>
                        <div className="flex flex-col gap-5">
                            <div className="flex justify-between">
                                <div className="w-[40%]">
                                    <SearchBar2 searchIcon={<i className='bx bx-search text-[1.2rem] text-[#C8C8C8]'></i>} searchIconClassName="top-0 bottom-0 absolute md:left-0 right-4 w-[10%] flex justify-center items-center" inputClassName="bg-transparent w-full h-full py-3 border-[1px] border-[#C8C8C8] outline-0 px-5 ps-10 rounded-[5px] text-[#B0B0B0]" placeholder="Search" />
                                </div>
                                <CustomButton icon={<i className='bx bx-plus'></i>} buttonText="Add New Job" btnClassName="bg-[#00753E] flex items-center text-[#FBFBFB] rounded-[5px]" onClick={() => navigate('add-job')} />
                            </div>
                            <div className="">
                                <JobDynamicTable
                                    showCheckbox={true}
                                    tableIcon3={(
                                        <NavLink
                                            to="/admin/seller-profile"
                                            className="text-primary dark:text-primary-400"
                                            data-twe-toggle="tooltip"
                                            title="Delete Market"
                                        >
                                            <i className='bx bx-trash cursor-pointer border-[1px] border-[#E8E8E8] py-2 px-2 rounded-[5px] text-[#161616] lg:text-[.8rem] windowScreen:text-[1.4rem]'></i>
                                        </NavLink>
                                    )}
                                    data={currentItems}
                                    clickedToEdit={(data) => navigate('edit-job', { state: data })} // Navigate to edit market
                                />
                                <div className="flex justify-end w-full pb-[2rem]">
                                    <Pagination 

                                        activePage={currentPage} 
                                        totalPages={totalPages} 
                                        onPageChange={handlePageChange} 
                                   
                                   
                                   />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AdminJob;
