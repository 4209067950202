import React from 'react'
import { NavLink } from "react-router-dom";
import bookMarkImg from "../assets/carousel-img/bookmark.png";

const MarketPlaceComponent = ({
    trendsAdsCustomProp,
    adsContainer,
    adsDescription,
    gridCon,
    imgStyles,
    bookmarkStyles,
    bookmarkContainer,
    titleStyles,
    itemLocation,
    productCard,
    itemPrice,
}) => {
    console.log(`TrendingAdsComponents data:`, trendsAdsCustomProp);
    return (
        <div className={`${adsContainer}`}>
            <div className={`${gridCon}`}>
                {trendsAdsCustomProp.map((item, index) => (
                    <div key={index} className={`${productCard}`}>
                        <NavLink to={`${item.productLink}`}>
                            <div
                                className={`bg-no-repeat object-cover bg-center bg-cover bg-inherit transition-transform hover:duration-700 hover:transform hover:scale-[1.05] relative ${imgStyles}`}
                                style={{ backgroundImage: `url(${item.image})` }}>
                                <div className={`absolute w-full h-full top-4 md:top-7 pe-5 flex justify-end items-end ${bookmarkContainer}`}>
                                </div>
                            </div>
                        </NavLink>
                        <div className={`${adsDescription}`}>
                            <p className={`${itemLocation}`}>{item.location}</p>
                            <p className={`${titleStyles}`}>
                                {item.title}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default MarketPlaceComponent
