import React from 'react';

const Pagination = ({ activePage, totalPages, onPageChange }) => {
  const handleClick = (pageNumber) => {
    if (pageNumber < 1 || pageNumber > totalPages) return;
    onPageChange(pageNumber);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5; // Number of page links to show in the pagination

    let startPage = Math.max(activePage - Math.floor(maxPagesToShow / 2), 1);
    let endPage = startPage + maxPagesToShow - 1;

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(endPage - maxPagesToShow + 1, 1);
    }

    if (startPage > 1) {
      pageNumbers.push(
        <li key={1} className={`pagination-item ${activePage === 1 ? 'active' : ''}`} onClick={() => handleClick(1)}>
          1
        </li>
      );
      if (startPage > 2) {
        pageNumbers.push(<li key="ellipsis-start" className="pagination-item">...</li>);
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={`pagination-item ${activePage === i ? 'active' : ''}`}
          onClick={() => handleClick(i)}
        >
          {i}
        </li>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageNumbers.push(<li key="ellipsis-end" className="pagination-item">...</li>);
      }
      pageNumbers.push(
        <li key={totalPages} className={`pagination-item ${activePage === totalPages ? 'active' : ''}`} onClick={() => handleClick(totalPages)}>
          {totalPages}
        </li>
      );
    }

    return pageNumbers;
  };

  return (
    <ul className="flex justify-center gap-2 md:gap-5 list-none mt-8">
      <li
        className={`pagination-item ${activePage === 1 ? 'disabled' : ''}`}
        onClick={() => handleClick(activePage - 1)}
      >
        &lt;
      </li>
      {renderPageNumbers()}
      <li
        className={`pagination-item ${activePage === totalPages ? 'disabled' : ''}`}
        onClick={() => handleClick(activePage + 1)}
      >
        &gt;
      </li>
    </ul>
  );
};

export default Pagination;
