import { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import ShopSmart from "../components/ShopSmartSection";
import TrendingAds from "../components/TrendingAdsSection";
import UniqueLifeSection from "../components/UniqueLifeSection";
import { appStore, authStore } from "../store";
import { adminGetAllCategory as getAllCategories, getAllProducts, getAllMarket } from "../api";
import { Crisp } from "crisp-sdk-web";
import MarketPlace from "../components/MarketPlace";

const LandingPage = () => {
    const [products, setProducts] = useState([]);
    const [markets, setMarkets] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const auth = authStore(state => state);
    const app = appStore(state => state);
    Crisp.configure("d9fca5d4-16b4-4c6c-a9e8-71be2c0cf8a9");

    const fetchCategoryData = async () => {
        app.startLoader();
        await getAllCategories(null).then(async response => {
            const categories = [];
            console.log(response);
            response?.map(category => {
                categories.push({
                    id: category.id,
                    name: category.name,
                    subCategory: category.subCategory || null,
                    status: category.status || true,
                });
            });
            auth.setCategories(categories);
        }).catch(e => {
            console.log(e.message);
        }).finally(() => {
            app.stopLoader();
        });
    };

    const fetchProductData = async (page) => { 
        app.startLoader();
        try {
            const response = await getAllProducts(page);
            setProducts(response);
            auth.setProducts(response);
        } catch (e) {
            console.log(e.message);
        } finally {
            app.stopLoader();
        }
    };

    const fetchmarketData = async (page) => { 
        app.startLoader();
        try {
            const response = await getAllMarket(page);
            console.log(`marketting ${JSON.stringify(response)}`)
            setMarkets(response);
            auth.setMarkets(response);
        } catch (e) {
            console.log(e.message);
        } finally {
            app.stopLoader();
        }
    };

    useEffect(() => {
        fetchCategoryData();
        fetchProductData(currentPage);
        fetchmarketData(currentPage);
    }, []);

    useEffect(() => {
        fetchProductData(currentPage);
    }, [currentPage]);

    useEffect(() => {
        fetchmarketData(currentPage);
    }, [currentPage]);
    
    console.log(`Products:`, products);
    console.log(`market:`, markets);
    console.log(`market:`, markets);



    return (
        <section className="overflow-x-hidden w-full relative">
            <Navbar token={!!auth.user?.token} role={auth.role} />
            <div className="relative mt-[8.5rem] md:mt-[6.5rem] w-full">
                <ShopSmart />
                <UniqueLifeSection />
                <MarketPlace  data={markets} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
                <TrendingAds data={products} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                <Footer />
            </div>
        </section>
    );
};

export default LandingPage;
