import React from 'react'
import LogoHome from './Logo';
import AgentSideBar from './AgentSidebar';

const AgentSideBarContainer = ({isSidebarOpen}) => {
      const sidebarClasses = isSidebarOpen ? '' : 'hidden md:flex';
      return (
        <section className={`bg-[rgb(247, 248, 250)] border-r border-[#EBECF2] w-28 md:w-64 flex flex-col items-center md:items-start justify-between py-8 transition-all duration-300 ${sidebarClasses}`}>
                <div className="w-full flex justify-center items-center h-[15%] px-5">
                    <LogoHome LogoClassName="lg:w-[60%] md:w-[40%] w-[70%]" />
                </div>
                <div className="w-full flex justify-center items h-[85%]">
                    <AgentSideBar />
                </div>
        </section>
      )
    }

export default AgentSideBarContainer
