import {useState, useEffect } from "react";
import { appStore, authStore } from "../../store";
import LogoHome from "../../components/Logo";
import SidebarNavLink from "../../components/SidebarNavLink";
import DummyImg from "../../assets/dummy-img.png";
import { NavLink, useLocation } from "react-router-dom";
import LoginActivityDynamicTable from "../../components/LoginActivityDynamicTable";
import AdminNavbar from "../../components/AdminNavbar";
import LogoSidebar from "../../components/LogoSidebar";


const AdminSellerProfile = () => {
    const auth = authStore(state => state);
    const app = appStore(state => state);
    const [isSidebarOpen, setSidebarOpen] = useState(false);
    const toggleSidebar = () => {
      setSidebarOpen(!isSidebarOpen);
    };

    let location = useLocation();
    const data = location.state;
    console.log(`the route data ${JSON.stringify(data)}`)

    const sellersData = [
        {
            Devices: 'Windows',
            Browser: 'Google Chrome',
            Location: 'Port Harcourt',
            Session: '2 days ago',
            verified: true,
        },
        {
            Devices: 'Mac',
            Browser: 'Firefox',
            Location: 'Port Harcourt',
            Session: '5 days ago',
            verified: false,
        },
        {
            Devices: 'IOS',
            Browser: 'Safari',
            Location: 'Port Harcourt',
            Session: '9 days ago',
            verified: false,
        },
    ];
    

    useEffect(() => {
        app.startLoader();
        setTimeout(() => {
            app.stopLoader();
        }, 3000);
    }, [auth.user]);
    return ( 
        <section className="overflow-x-hidden h-[100vh] w-full flex relative">
            <LogoSidebar isSidebarOpen={isSidebarOpen}/>
            <div className="w-full">
                <AdminNavbar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} token={!!auth.user?.token} />
                <div className="w-full min-h-[calc(100%-5rem)] mt-[2rem] pt-[4rem] lg:px-1 windowScreen:px-[2rem] pb-[2rem] flex flex-col gap-10">
                    <div className="flex flex-col gap-5 lg:px-2 windowScreen:px-5">
                        <h1 className="font-semibold text-[#110D06] lg:text-xl macScreens:text-xl">Seller Profile</h1>
                        <div className="w-full border rounded-[10px] lg:px-5 windowScreen:px-7 py-7 flex flex-col gap-14">
                            <div className="flex flex-col gap-5">
                                <div className="flex justify-start items-center gap-5 w-[50%]">
                                    <div className="w-[20%]">
                                        <img className="w-full" src={data.avatar} alt="" />
                                    </div>
                                    <p className="lg:text-sm macScreens:text-lg text-[#161616] font-medium">{`${data.firstName} ${data.lastName}`}</p>
                                </div>
                                <p className="text-lg text-[#161616] font-medium lg:text-sm macScreens:text-lg">Personal Information</p>
                                <table className="w-full bg-transparent rounded-[10px]">
                                    <thead>
                                        <tr>
                                            <th className="text-start font-medium text-[#848484] font-Nunito py-1 lg:text-[.6rem] windowScreen:text-sm">First Name</th>
                                            <th className="text-start font-medium text-[#848484] font-Nunito py-1 lg:text-[.6rem] windowScreen:text-sm">Last Name</th>
                                            <th className="text-start font-medium text-[#848484] font-Nunito py-1 lg:text-[.6rem] windowScreen:text-sm">Email Address</th>
                                            <th className="text-start font-medium text-[#848484] font-Nunito py-1 lg:text-[.6rem] windowScreen:text-sm">Phone Number</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="py-1 text-[#161616] font-Nunito font-semibold lg:text-[.6rem] windowScreen:text-sm">{data.firstName}</td>
                                            <td className="py-1 text-[#161616] font-Nunito font-semibold lg:text-[.6rem] windowScreen:text-sm">{data.lastName}</td>
                                            <td className="py-1 text-[#161616] font-Nunito font-semibold lg:text-[.6rem] windowScreen:text-sm">{data.email}</td>
                                            <td className="py-1 text-[#161616] font-Nunito font-semibold lg:text-[.6rem] windowScreen:text-sm">{data.phoneNumber}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="flex flex-col gap-5">
                                <p className="text-lg text-[#161616] font-medium lg:text-sm macScreens:text-lg">Login Activity</p>
                                <div className="col-span-2">
                                    <LoginActivityDynamicTable 
                                    showCheckbox={true}
                                    tableIcon2={
                                        <NavLink
                                        to="/admin/seller-profile"
                                        className="text-primary dark:text-primary-400"
                                        data-twe-toggle="tooltip"
                                        title="View seller"
                                        ><span className="material-symbols-outlined text-[1rem] lg:text-[1.5rem] xl:text-[1.8rem] text-[#161616] cursor-pointer border-[1px] border-[#E8E8E8] py-1 px-1 rounded-[5px]">visibility</span>
                                        </NavLink>
                                    }
                                    data={sellersData} 
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
     );
}
 
export default AdminSellerProfile;