import {useState, useEffect, useRef } from "react";
import { appStore, authStore } from "../../store";
import NavbarDashboard from "../../components/NavbarDashboard";
import LogoHome from "../../components/Logo";
import SidebarNavLink from "../../components/SidebarNavLink";
import ProfileImg from "../../assets/profile-img-2.png";
import CustomButton from "../../components/CustomButton";
import InputField from "../../components/InputFieldComponents";
import AgentSideBarContainer from "../../components/AgentSideBarContainer";
import { agentUpdateProfilePicture, sellerUpdateProfilePicture } from "../../api";
import { toastError, toastSuccess, toastWarning } from "../../utils/constants";
import toast from 'react-hot-toast';
import api from "../../api/server";
import './load.css'





const AgentSettings = () => {
    const auth = authStore(state => state);
    const app = appStore(state => state);
    const [isSidebarOpen, setSidebarOpen] = useState(false);
    const [passwords, setPasswords] = useState({ currentPassword: '', newPassword: '', confirmNewPassword: '' });
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
       // handle profile image upload
       const [profileImage, setProfileImage] = useState(null);
       const [profileImageUrl, setProfileImageUrl] = useState(null);
       const [base64Image, setBase64Image] = useState(null);
       const [loading,setLoading]=useState(false);
       const handleProfileImageChange = (e) => {
        console.log(`file selected ${JSON.stringify(e.target.files)}`)
           setProfileImage(e.target.files);
       }
       const fileInputRef=useRef(null)


       const handleImageClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click(); // Trigger the click on the hidden input
        }
    };
    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
    };

    
    const handlePasswordChange = (e) => {
        setPasswords({ ...passwords, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setSuccess(null);

        if (passwords.newPassword !== passwords.confirmNewPassword) {
            setError("New passwords do not match.");
            return;
        }

    };



    const uploadFileCustom = async (file) => {
        try {
          const formData = new FormData();
      
          // Check if a file is selected
          if (file && file[0]) {
            console.log(`file ${JSON.stringify(file[0])}`);
            formData.append('file', file[0]);
      
            // Upload the file using an API call
            const response = await api.post('/product/uploadimage', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });
      
            // Return the file URL from the response
            return response.fileUrl;
          } else {
            throw new Error('No file selected');
          }
        } catch (error) {
          console.error('Error uploading file:', error);
          toast.error('Failed to upload the file.');
          return null;
        }
      };

    const handleProfileImageUpload = async (image) => {
        if (!image) {
            toast.error("Please select an image to upload", toastError);
            return;
        }

setLoading(true);
        console.log(`image selected ${JSON.stringify(image)}`)
       

        const imageUploaded=await uploadFileCustom(image);

        setProfileImageUrl(imageUploaded)


       console.log(`upload url ${imageUploaded}`)

        

        app.startLoader();

        await agentUpdateProfilePicture({
            "avatar": imageUploaded,
            
          },
          { Authorization: `Bearer ${auth.user.token}` }
        ).then(response => {
            // if (!response.success) throw Error(response.message);
            toast('Profile Picture Updated', toastSuccess);


         
        }).catch(e => {
            console.log(e.message);
            if (e.message === 'jwt expired') {
                auth.logout();
            }
            throw Error(e.message);
        }).finally(() => {
            app.stopLoader();
        });

        app.stopLoader();

        // await getUserDetails()
    
       
    }


    const getUserDetails = async () => {
        const response = await getUserDetails(auth.user.token);
        console.log(`the user details ${JSON.stringify(response)}`)
                    // setUser(response);
    }

    useEffect(() => {
        app.startLoader();
      
        if (profileImage) {
            handleProfileImageUpload(profileImage);
        }
        setTimeout(() => {
            app.stopLoader();
          
        }, 3000);
    }, [auth.user,profileImage]);
    return ( 
        <section className="overflow-x-hidden h-[100vh] w-full flex relative">
            <AgentSideBarContainer isSidebarOpen={isSidebarOpen}/>
            <div className="w-full">
                <NavbarDashboard isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} token={!!auth.user?.token} />
                <div className="w-full min-h-[calc(100%-5rem)] pt-[6rem] px-[2rem] pb-[5rem] mt-[2rem] lg:pt-[5rem] lg:px-5 macScreens:px-[2rem] lg:pb-5 macScreens:pb-[5rem] gap-x-10 flex flex-col gap-5">
                    <h1 className="font-semibold text-[#110D06] lg:text-xl macScreens:text-xl">Settings</h1>
                    <div className="flex justify-between w-[85%]">
                        <div className="flex flex-col gap-1">
                            <h1 className="text-[#360A13] lg:text-sm macScreens:text-lg"> {auth.user.firstName} {auth.user.lastName}</h1>
                            <p className="text-base text-[#ADADAD] font-Nunito lg:text-xs macScreens:text-sm">Your Agent ID {auth.user.userId}</p>
                        </div>
                        <div className="w-[100px]">


                        <img 
                                className="w-full rounded-[100%] cursor-pointer" 
                                src={profileImageUrl?profileImageUrl: ProfileImg} 
                                alt="" 
                                onClick={handleImageClick} // Attach the click handler
                            />
                            <input 
                                type="file" 
                                accept="image/*" 
                                className="hidden dp" 
                                onChange={handleProfileImageChange} 
                                ref={fileInputRef} // Attach the ref to the input
                            />
                            {/* <img className="w-full rounded-[100%]" src={ProfileImg} alt="" />
                            <input type="file" accept="image/*" className="hidden dp" onChange={handleProfileImageChange} /> */}



                        </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-7 w-full pb-[2rem]">
                        <div className="input-box flex flex-col gap-1 lg:gap-2 macScreens:gap-2  macScreens:text-[1rem] lg:text-[.8rem]">
                            <InputField label="First Name" name="first-name" value={auth.user.firstName} placeholder="Enter your first name here..." type="text" required onChange={(e) => console.log(e)} />
                        </div>
                        <div className="input-box flex flex-col gap-1 lg:gap-2 macScreens:gap-2  macScreens:text-[1rem] lg:text-[.8rem]">
                            <InputField label="Last Name" name="last-name" value={auth.user.lastName} placeholder="Enter your last name here..." type="text" required onChange={(e) => console.log(e)} />
                        </div>
                        <div className="input-box flex flex-col gap-1 lg:gap-2 macScreens:gap-2  macScreens:text-[1rem] lg:text-[.8rem]">
                            <InputField label="Email Address" name="email" value={auth.user.email} placeholder="Enter your email address here..." type="email" required onChange={(e) => console.log(e)} />
                        </div>
                        <div className="input-box flex flex-col gap-1 lg:gap-2 macScreens:gap-2  macScreens:text-[1rem] lg:text-[.8rem]">
                            <InputField label="Phone Number" name="phone" value={auth.user.phoneNumber} placeholder="Enter your business phone number here..." type="tel" required onChange={(e) => console.log(e)} />
                        </div>
                    </div>
                    <div className="lg:flex flex-col gap-7 w-full lg:w-[50%] hidden">
                        <div className="">
                            <h1 className='font-semibold lg:text-sm macScreens:text-lg text-[#110D06]'>Password</h1>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="w-full grid grid-cols-1 gap-7 pe-5 md:pe-0">
                                <div className="input-box flex flex-col gap-1 lg:gap-2 macScreens:gap-2 macScreens:text-[1rem] lg:text-[.8rem] relative">
                                    <InputField label="Current Password" name="currentPassword" value={passwords.currentPassword} placeholder="Enter your current password..." type="password" required onChange={handlePasswordChange} />
                                </div>
                                <div className="input-box flex flex-col gap-1 lg:gap-2 macScreens:gap-2 macScreens:text-[1rem] lg:text-[.8rem] relative">
                                    <InputField label="New Password" name="newPassword" value={passwords.newPassword} placeholder="Enter your new password..." type="password" required onChange={handlePasswordChange} />
                                </div>
                                <div className="input-box flex flex-col gap-1 lg:gap-2 macScreens:gap-2 macScreens:text-[1rem] lg:text-[.8rem] relative">
                                    <InputField label="Confirm New Password" name="confirmNewPassword" value={passwords.confirmNewPassword} placeholder="Confirm your new password..." type="password" required onChange={handlePasswordChange} />
                                </div>
                            </div>
                            {error && <div className="text-red-500 text-sm mt-2">{error}</div>}
                            {success && <div className="text-green-500 text-sm mt-2">{success}</div>}
                            <CustomButton type="submit" buttonText="Update Password" btnClassName='w-[90%] mt-6 lg:w-full lg:text-[1rem] text-[.8rem] text-[#fff] bg-[#00753E] hover:bg-[#0a1b13]' />
                        </form>
                    </div>
                </div>
            </div>
        </section>
     );
}
 
export default AgentSettings;