import InputField from "./InputFieldComponents";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import WYSIWYG from "./WYSIWYG";
import CustomButton from "./CustomButton";
import toast from 'react-hot-toast';
import api from "../api/server";
import { toastError, toastSuccess } from "../utils/constants";

const JobApplicationForm = () => {
    const navigate = useNavigate();

    // State for job form fields
    const [jobData, setJobData] = useState({
        jobTitle: "",
        jobDescription: "",
        qualifications: "",
        experience: "",
        screeningQuestions: [],
        jobFiles: {
            jobDescriptionFile: null,
            companyPolicyFile: null
        }
    });

    // Add screening question
    const [screeningQuestions, setScreeningQuestions] = useState([]);

    const addScreeningQuestion = () => {
        setScreeningQuestions([...screeningQuestions, { id: Date.now(), question: "" }]);
    };

    const removeScreeningQuestion = (id) => {
        setScreeningQuestions((prevQuestions) => prevQuestions.filter(q => q.id !== id));
    };

    const handleInputChange = (e) => {
        setJobData({
            ...jobData,
            [e.target.name]: e.target.value
        });
    };

    const handleQuestionChange = (e, id) => {
        const { value } = e.target;
        setScreeningQuestions((prevQuestions) => prevQuestions.map(q => (q.id === id ? { ...q, question: value } : q)));
    };

    const handleFileChange = (e) => {
        const name = e.target.name;
        const file = e.target.files[0];
        setJobData((prevData) => ({
            ...prevData,
            jobFiles: {
                ...prevData.jobFiles,
                [name]: file
            }
        }));
    };

    const validateJobData = (data) => {
        const requiredFields = ["jobTitle", "jobDescription", "qualifications", "experience"];
        let errorShown = false;

        requiredFields.forEach((field) => {
            if (!data[field]) {
                toast(`${field.replace(/([A-Z])/g, ' $1').trim()} is required`, toastError);
                errorShown = true;
            }
        });

        return !errorShown;
    };

    const uploadFiles = async () => {
        const fileUrls = [];
        for (const key in jobData.jobFiles) {
            if (jobData.jobFiles[key] !== null) {
                try {
                    const formData = new FormData();
                    formData.append('file', jobData.jobFiles[key]);

                    const response = await api.post('/job/uploadfile', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });

                    fileUrls.push(response.fileUrl);
                } catch (error) {
                    console.error('Error uploading file:', error);
                }
            }
        }
        return fileUrls;
    };

    const handleJobSubmit = async () => {
        const isValid = validateJobData(jobData);
        if (!isValid) return;

        let fileUrls = await uploadFiles();
        console.log("Uploaded file URLs:", fileUrls);

        // Submit job post with form data and uploaded file URLs
        try {
            const response = await api.post('/job/create', {
                ...jobData,
                screeningQuestions,
                fileUrls
            });
            if (response.status === 200) {
                toast("Job posted successfully!", toastSuccess);
                navigate('/seller/jobs');
            }
        } catch (error) {
            console.error("Error posting job:", error);
            toast("Error posting job", toastError);
        }
    };

    return (
        <div className="flex flex-col gap-4 h-full">
            <div className="flex gap-2 items-center">
                <i className="bx bx-arrow-back text-[2rem]" onClick={() => navigate(-1)}></i>
                <h1 className="font-semibold text-[1.5rem] text-[#000]">Post a Job</h1>
            </div>
            <div className="flex justify-center w-full h-full">
                <div className="w-full flex flex-col gap-5">
                    <div className="w-full flex flex-col gap-5 py-5 px-7 rounded-[10px] border-[1px] border-[#848484]">
                        <InputField
                            label="Job Title"
                            name="jobTitle"
                            placeholder="Enter job title..."
                            type="text"
                            onChange={handleInputChange}
                        />
                        <div className="input-box flex flex-col gap-1 lg:gap-2">
                            <label className="text-[#161616]">Job Description</label>
                            <WYSIWYG getContent={(data) => setJobData({ ...jobData, jobDescription: data })} />
                        </div>
                        <InputField
                            label="Qualifications"
                            name="qualifications"
                            placeholder="Enter required qualifications..."
                            type="text"
                            onChange={handleInputChange}
                        />
                        <InputField
                            label="Experience"
                            name="experience"
                            placeholder="Enter required experience..."
                            type="text"
                            onChange={handleInputChange}
                        />
                        <div className="w-full">
                            <button onClick={addScreeningQuestion} className="flex items-center gap-5">Add Screening Question <i className="bx bx-plus text-[#00753E] text-[1.5rem]"></i></button>
                            {screeningQuestions.map((q, index) => (
                                <div key={q.id} className="mt-4 flex gap-4">
                                    <InputField
                                        label={`Question ${index + 1}`}
                                        name={`question${index}`}
                                        placeholder="Enter screening question..."
                                        type="text"
                                        value={q.question}
                                        onChange={(e) => handleQuestionChange(e, q.id)}
                                    />
                                    <CustomButton
                                        buttonText="Remove"
                                        btnClassName="bg-[#ff3030] text-white rounded-[10px]"
                                        onClick={() => removeScreeningQuestion(q.id)}
                                    />
                                </div>
                            ))}
                        </div>
                        <div className="flex flex-col gap-3 mt-5">
                            <label className="text-[#161616]">Upload Job Description File</label>
                            <input
                                type="file"
                                accept=".pdf,.doc,.docx"
                                name="jobDescriptionFile"
                                onChange={handleFileChange}
                            />
                        </div>
                        <div className="flex flex-col gap-3">
                            <label className="text-[#161616]">Upload Company Policy File (Optional)</label>
                            <input
                                type="file"
                                accept=".pdf,.doc,.docx"
                                name="companyPolicyFile"
                                onChange={handleFileChange}
                            />
                        </div>
                        <div className="flex justify-end mt-5">
                            <CustomButton
                                buttonText="Post Job"
                                btnClassName="bg-[#00753E] text-[#FFF] rounded-[10px] border-[1px] border-[#00753E] lg:py-2 lg:-mb-2"
                                onClick={handleJobSubmit}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};



export default JobApplicationForm;
