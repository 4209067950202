

// AnotherComponent.js
import React from 'react';
import ProductDetails from './ProductDetails';


const productDetailsArray2 = [
  { label: "TYPE", value: "Laptop" },
  { label: "MODEL", value: "EliteBook x360 1030 G2" },
  { label: "RAM", value: "16GB" },
  { label: "NUMBER OF CORES", value: "Dual Core" },
  { label: "STORAGE TYPE", value: "SSD" },
  { label: "GRAPHICS CARD", value: "Intel" },
  { label: "COLOR", value: "Silver" },
  { label: "TYPE", value: "Laptop" },
  { label: "MODEL", value: "EliteBook x360 1030 G2" },
  { label: "RAM", value: "16GB" },
  { label: "NUMBER OF CORES", value: "Dual Core" },
  { label: "STORAGE TYPE", value: "SSD" },
  { label: "GRAPHICS CARD", value: "Intel" },
  { label: "COLOR", value: "Silver" },
];

const ProductCard2 = ({data}) => {
  console.log(data);

  const productDetailsArray = data.map((item) => {
    return {
      label: item.name,
      value: item.value,
    };
  });

  return (
    <div className="flex gap-x-5 w-60 md:w-80">
      <ProductDetails productDetails={productDetailsArray} />
      {/* <ProductDetails productDetails={productDetailsArray2} /> */}
    </div>
  );
};

export default ProductCard2;
 
// export default ProductCard2;