import { useEffect, useState } from "react";
import { appStore, authStore } from "../../store";
import InputField from "../../components/InputFieldComponents";
import CustomButton from "../../components/CustomButton";
import BuyerPathBreadCrumb from "../../components/BuyerPath";
import AdminNavbar from "../../components/AdminNavbar";
import AdminSideBar from "../../components/AdminSidebar";
import toast from 'react-hot-toast';
import { toastError, toastSuccess } from "../../utils/constants";
import { useNavigate, useLocation } from "react-router-dom";
import LogoSidebar from "../../components/LogoSidebar";
import { adminCreateMarket, adminEditMarket } from "../../api";
import pictureImg from '../../assets/product-media-icon.png';
import api from "../../api/server";
import StateAndLgaData from "../../api/data.json";
import AgentSideBarContainer from "../../components/AgentSideBarContainer";

const AdminAddMarket = () => {
    const auth = authStore(state => state);
    const app = appStore(state => state);
    const navigate = useNavigate();
    
    const location = useLocation();
    const data = location.state; // Edit or Add new market
    const [isSidebarOpen, setSidebarOpen] = useState(false);
    const toggleSidebar = () => setSidebarOpen(!isSidebarOpen);
    const [state] = useState(Object.keys({"Select a state": "", ...StateAndLgaData}).map((state) => {
        return {
            value: state,
            label: state
        }
    }));

    // Form state
    const [marketData, setMarketData] = useState({
        name: data?.name || '',
        location: data?.location || '',
        state:data?.location || '',
        picture: [],
    });

    const [picture, setpicture] = useState({
        file1: null,
        file2: null,
        file3: null,
        file4: null,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setMarketData({ ...marketData, [name]: value });
    };

    // Image uploading function
    const uploadImagesCustom = async () => {
        const fileUrls = [];
        for (const key in picture) {
            if (picture[key]) {
                try {
                    const formData = new FormData();
                    formData.append('file', picture[key]);

                    const response = await api.post('/product/uploadimage', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });
                    console.log(`response upload ${response.fileUrl}`)
                    fileUrls.push(response.fileUrl);
                } catch (error) {
                    console.error('Error uploading file:', error);
                    toast('Error uploading image', toastError);
                    return;
                    return fileUrls;
                }
            }
        }
        return fileUrls;
    };

    // Handle image selection
    const handleProfileImageChange = async (e) => {
        const { name } = e.target;
        const file = e.target.files[0];
        if (file) {
            setpicture((prevpicture) => ({
                ...prevpicture,
                [name]: file,
            }));
        }
    };

    // Create market function
    const createMarket = async () => {
        app.startLoader();
        try {
            const uploadedImages = await uploadImagesCustom();
            console.log(`uploaded images ${uploadedImages}`)
            const newMarketData = { ...marketData, picture: uploadedImages };

            await adminCreateMarket({
               name: newMarketData.name,
               location:newMarketData.state+','+newMarketData.location,
               picture:newMarketData.picture
            }, { Authorization: `Bearer ${auth.user.token}` });
            toast('Market Created', toastSuccess);
            setMarketData({ name: '', location: '', picture: [] });
            setTimeout(() => navigate(-1), 1000);
        } catch (error) {
            toast(error.message || 'Failed to create market', toastError);
            if (error.message === 'jwt expired') auth.logout();
        } finally {
            app.stopLoader();
        }
    };

    // Edit market function
    const editMarket = async () => {
        app.startLoader();
        try {
            if (marketData.name === data.name && marketData.location === data.location && JSON.stringify(marketData.picture) === JSON.stringify(data.picture)) {
                throw new Error("No changes made");
            }
            const uploadedImages = await uploadImagesCustom();
            const updatedMarketData = { ...marketData, picture: uploadedImages };

            await adminEditMarket(updatedMarketData, data.id, { Authorization: `Bearer ${auth.user.token}` });
            toast('Market Updated', toastSuccess);
            setTimeout(() => navigate(-1), 1000);
        } catch (error) {
            toast(error.message || 'Failed to update market', toastError);
            if (error.message === 'jwt expired') auth.logout();
        } finally {
            app.stopLoader();
        }
    };

    useEffect(() => {
        app.startLoader();
        setTimeout(() => app.stopLoader(), 3000);
    }, [auth.user,location.pathname]);

    return (
        <section className="overflow-x-hidden h-[100vh] w-full flex relative">

               
           {location.pathname.includes('agent')?
           
           <AgentSideBarContainer isSidebarOpen={isSidebarOpen} />
        :
        <LogoSidebar isSidebarOpen={isSidebarOpen}/>
        }
        
            <div className="w-full">
                <AdminNavbar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} token={!!auth.user?.token} />
                <div className="w-full min-h-[calc(100%-5rem)] mt-[2rem] lg:pt-[5rem] lg:px-5 macScreens:px-[2rem] lg:pb-5 macScreens:pb-[5rem] flex flex-col gap-5 macScreens:gap-10">
                    <BuyerPathBreadCrumb home="Market" shop={!!data ? "> Edit Market" : "> Add New Market"} />
                    <div className="flex flex-col gap-5 px-2 windowScreen:px-5">
                        <h1 className="font-semibold text-[#110D06] lg:text-xl macScreens:text-xl">{!!data ? "Edit Market" : "Add New Market"}</h1>
                        <div className="flex flex-col gap-7 w-full px-7 py-12">
                            <InputField label="Market Name" name="name" value={marketData.name} placeholder="Enter market name" type="text" onChange={handleChange} required />
                           
                            <InputField label="State*" name="state" type="select" options={state} required selected={(selectedData) => {
                                    // setSelectedState(selectedData);
                                    console.log(`selected data ${selectedData}`)
                                    setMarketData({ ...marketData, state: selectedData });
                                }} />
                           
                            <InputField label="City" name="location" value={marketData.location} placeholder="Enter market location" type="text" onChange={handleChange} required />
                            
                            
                            
                            <div className="input-box flex flex-col gap-1 lg:gap-2 macScreens:gap-2 macScreens:text-[1rem] lg:text-[.8rem]">
                                <label className="text-[.6rem] md:text-[1rem]">Product Media</label>
                                <div className="w-[80%] grid md:grid-cols-4 gap-y-5 md:gap-x-5">
                                    {Object.entries(picture).map(([key, value]) => (
                                        <div key={key} onClick={() => document.querySelector(`input[name=${key}]`).click()} className="cursor-pointer border-[1px] border-[#D0D5DD] border-dashed rounded-[10px] h-[200px] px-5 flex flex-col gap-3 justify-center items-center">
                                            <input type="file" accept="image/*" className="hidden" onChange={handleProfileImageChange} name={key} />
                                            <img src={value ? URL.createObjectURL(value) : pictureImg} alt="" className="object-contain" />
                                            {!value && <p className="text-[#848484] text-xs text-center">Click to upload or update a media</p>}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="flex flex-col gap-y-10 w-full col-span-2">
                                <div className="input-box justify-end flex gap-1 lg:gap-2 macScreens:gap-2 macScreens:text-[1rem] lg:text-[.8rem] w-full">
                                    <CustomButton buttonText="Cancel" btnClassName="bg-transparent font-semibold text-[#000] text-sm windowScreen:text-lg rounded-[5px]" onClick={() => navigate(-1)} />
                                    <CustomButton buttonText={!!data ? "Edit" : "Add"} btnClassName="bg-[#00753E] text-[#FBFBFB] text-sm windowScreen:text-lg rounded-[5px]" onClick={() => { data ? editMarket() : createMarket(); }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AdminAddMarket;
