import LogoHome from "./Logo";
import SearchBar from "./SearchbarComponent"
import NavbarSubsection from "./NavbarSubsection";
import SellerProfileHeader from "./SellerProfileHeader";

const SellerNavbar = () => {
    return ( 
        <nav className="navbar flex flex-col gap-3 md:gap-0 justify-center items-center pb-2 pt-5 bg-[#00753E] shadow-md w-full">
            <div className="flex justify-between items-center w-[100%] macScreens:w-[90%] px-2">
                <LogoHome LogoClassName="lg:w-[50%] md:w-[40%] w-[30%] bg-white p-1 md:p-2" />
                <div className="hidden md:block lg:w-[40%] md:w-[40%] w-[70%]">
                    <SearchBar />
                </div>
                <div className="md:w-[30%] lg:w-[30%] xl:w-[25%]">
                    <SellerProfileHeader />
                </div>
            </div>
            <NavbarSubsection />
    </nav>
     );
}
 
export default SellerNavbar;