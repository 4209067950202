import { NavLink, useNavigate } from "react-router-dom";
import CustomButton from "./CustomButton";

const AuthButtonComponent = () => {
    const navigate = useNavigate();
    return ( 
        <div className="flex items-center gap-3">
            <NavLink to="/agent/auth/sign-in">
                <button
                    className="font-Poppins text-xs md:text-base macScreens:px-11 md:px-5 lg:px-7 px-3 py-1 md:py-3 lg:py-2 rounded-[3px] lg:rounded-[5px] lg:text-[1.1rem] text-[.8rem] hover:bg-[#0c2218] transition-all hover:duration-700"
                    style={{ backgroundColor: "#ffffff", color: "#00753E" }}
                >
                    Become a Agent
                </button>
            </NavLink>
            <NavLink to="/seller/auth/sign-up">
                <button
                    className="font-Poppins text-xs md:text-base macScreens:px-11 md:px-5 lg:px-7 px-3 py-1 md:py-3 lg:py-2 rounded-[3px] lg:rounded-[5px] lg:text-[1.1rem] text-[.8rem] hover:bg-[#0c2218] transition-all hover:duration-700"
                    style={{ backgroundColor: "#FFFFFF", color: "#00753E" }}
                >
                    PLACE AD/REGISTER
                </button>
            </NavLink>
            <CustomButton bgColor="#ffffff" textColor="#00753E" buttonText="Sign In" onClick={() => {
                navigate('/seller/auth/sign-in');
            }} />
            {/* <CustomButton bgColor="#ffffff" textColor="#00753E" buttonText="Sign Up" onClick={() => {
                navigate('/auth/sign-in');
            }} /> */}
        </div>
     );
}
 
export default AuthButtonComponent;