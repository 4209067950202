import React from 'react';
import Navbar from '../components/Navbar';
import { appStore, authStore } from '../store';
import Footer from '../components/Footer';
import ContactForm from '../components/ContactForm';
// import aboutImage from './path-to-your-image.jpg';

const About = () => {

  const auth = authStore(state => state);
    const app = appStore(state => state);
    
  return (
    <section className="overflow-x-hidden w-full relative">
            <Navbar token={!!auth.user?.token} role={auth.role} />
    <div className="relative mt-[8.5rem] md:mt-[6.5rem] max-w-4xl mx-auto p-6 text-center">
      <h1 className="text-4xl font-bold mb-6 text-gray-800">About Us</h1>
      <img src='' alt="About Us" className="w-full h-auto mb-6 rounded-lg shadow-lg" />
      <p className="text-lg text-gray-600 mb-6">
        Welcome to buymeNaija! We are dedicated to providing you with the best products and services. Our team works tirelessly to ensure you have a seamless shopping experience.
      </p>
      <h2 className="text-2xl font-semibold mb-4 text-gray-800">Our Mission</h2>
      <p className="text-lg text-gray-600 mb-6">
        Our mission is to deliver high-quality products at affordable prices while offering exceptional customer service. We believe in building lasting relationships with our customers based on trust and satisfaction.
      </p>
      <h2 className="text-2xl font-semibold mb-4 text-gray-800">Our Story</h2>
      <p className="text-lg text-gray-600 mb-6">
        buymeNaija was founded in [Year] with the vision of creating a one-stop shop for all your needs. Over the years, we have grown from a small startup to a leading e-commerce platform, thanks to the support of our loyal customers.
      </p>
      <h2 className="text-2xl font-semibold mb-4 text-gray-800">Contact Us</h2>
      <p className="text-lg text-gray-600 mb-6">
        If you have any questions or feedback, feel free to reach out to us at [Email Address] or call us at [Phone Number]. We are here to assist you 24/7.
      </p>
      <ContactForm/>
    </div>
      <Footer />
    </section>
  );
}

export default About;
