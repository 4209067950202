import TrendingAdsComponents from './TrendingAdsComponents';
import { getProductsBySellerId } from "../api";
import { useState, useEffect } from "react";
import { appStore, authStore } from "../store";

const SimilarAds = ({ HeaderText, showComponent, showClearButton, className, id }) => {

    const [products, setProducts] = useState([]);
    const auth = authStore(state => state);
    const app = appStore(state => state);

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchSellerProductData();
    }, [id]);

    const fetchSellerProductData = async () => {
        app.startLoader();
        try {
            const response = await getProductsBySellerId(id);
            setProducts(response);
            // auth.setProducts(response);

        } catch (e) {
            console.log(e.message);
        } finally {
            app.stopLoader();
        }
    };

    console.log(products);

    const productData = products.map((item) => {
        return {
            image: item.picture[0],
            title: item.name,
            location: "Lagos, Ikeja",
            price: item.basePrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            productLink: `/product-description/${item.id}`,
        };
    });

    return (
        <section className="bg-[#FFF] w-full flex flex-col justify-center items-center">
            <div className="md:w-[90%] w-[95%] pb-10 lg:pb-[5rem] flex flex-col justify-center items-center">
                <div className="w-full flex-col justify-center">
                    <div className={`w-full flex justify-between items-center py-5 border-b-2 ${className}`}>
                        <h1 className="text-black font-Poppins font-semibold text-[.8rem] md:text-[1.5rem]">{HeaderText}</h1>
                        {showComponent && (
                            <div className="flex gap-2 items-center  similarAd-grid-lists">
                                <i className='bx bx-list-ul text-[1.5rem]'></i>
                                <i className='bx bx-grid-alt text-[1.5rem] active-grid-list'></i>
                            </div>
                        )}
                    </div>
                    {showClearButton && (
                        <div className="flex justify-end mt-5">
                            <button className='font-medium text-[#00753E]'>Clear All</button>
                        </div>
                    )}
                </div>
                <TrendingAdsComponents
                    gridCon="grid lg:grid-cols-3 md:grid-cols-2 xl:grid-cols-4 macScreens:grid-cols-5 grid-cols-2 gap-x-2 gap-y-7 pt-7"
                    adsContainer="w-full pb-5 md:pb-[5rem]"
                    adsDescription="flex flex-col justify-center items-start gap-5 h-[50%] md:h-[35%] pb-7 md:py-7 md:px-5 px-2"
                    titleStyles="text-black font-semibold font-Nunito text-[.7rem] md:text-[1.1rem]"
                    imgStyles="h-[60%] md:h-[65%]"
                    itemLocation="text-[#848484] font-light text-[.5rem] md:text-[.8rem]"
                    productCard="h-[22rem] md:h-[35rem] overflow-hidden shadow-md"
                    itemPrice="font-Poppins font-semibold text-[#00753E] text-[1rem] md:text-[1.2rem] pb-7 md:pb-0"
                    trendsAdsCustomProp={productData.slice(0, 5)}
                />
            </div>
        </section>
    );
}

export default SimilarAds;
