import {useState, useEffect } from "react";
import { appStore, authStore } from "../../store";
import LogoHome from "../../components/Logo";
import SidebarNavLink from "../../components/SidebarNavLink";
import CustomButton from "../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import AdminProfileBoard from "../../components/AdminProfileBoard";
import AgentImg1 from "../../assets/agent-img-1.png"
import Pagination from "../../components/Pagination";
import SellerImg1 from "../../assets/seller-img-1.png"
import SellerImg2 from "../../assets/seller-img-2.png"
import SellerImg3 from "../../assets/seller-img-3.png"
import DynamicTableForAdmin from "../../components/DynamicTableForAdmin";
import AdminNavbar from "../../components/AdminNavbar";
import LogoSidebar from "../../components/LogoSidebar";



const AdminAgentProfileII = () => {
    const auth = authStore(state => state);
    const app = appStore(state => state);
    const [isSidebarOpen, setSidebarOpen] = useState(false);
    const toggleSidebar = () => {
      setSidebarOpen(!isSidebarOpen);
    };

    const navigate = useNavigate();

    const goBack = () => {
      navigate(-1); // Navigate back one step
    };

    const sellersData = [
        {
            imgSrc: SellerImg1,
            name: 'Jenny Wilson',
            mobile: '(808) 555-0111',
            email: 'jenny.wilson@demo.com',
            address: '8502 Preston Rd. Inglewood, Maine'
        },
        {
            imgSrc: SellerImg2,
            name: 'Esther Howard',
            mobile: '(239) 555-0108',
            email: 'esther.howard@demo.com',
            address: '3891 Ranchview Dr. Richardson, CA'
        },
        {
            imgSrc: SellerImg3,
            name: 'Darlene Robertson',
            mobile: '(239) 555-0108',
            email: 'darlene.r@demo.com',
            address: '2972 Westheimer Rd. Santa Ana,'
        },
        {
            imgSrc: SellerImg3,
            name: 'Darlene Robertson',
            mobile: '(239) 555-0108',
            email: 'darlene.r@demo.com',
            address: '2972 Westheimer Rd. Santa Ana,'
        },
        {
            imgSrc: SellerImg1,
            name: 'Jenny Wilson',
            mobile: '(808) 555-0111',
            email: 'jenny.wilson@demo.com',
            address: '8502 Preston Rd. Inglewood, Maine'
        },
        {
            imgSrc: SellerImg2,
            name: 'Esther Howard',
            mobile: '(239) 555-0108',
            email: 'esther.howard@demo.com',
            address: '3891 Ranchview Dr. Richardson, CA'
        },
        {
            imgSrc: SellerImg3,
            name: 'Darlene Robertson',
            mobile: '(239) 555-0108',
            email: 'darlene.r@demo.com',
            address: '2972 Westheimer Rd. Santa Ana,'
        },
        {
            imgSrc: SellerImg3,
            name: 'Darlene Robertson',
            mobile: '(239) 555-0108',
            email: 'darlene.r@demo.com',
            address: '2972 Westheimer Rd. Santa Ana,'
        },
        {
            imgSrc: SellerImg1,
            name: 'Jenny Wilson',
            mobile: '(808) 555-0111',
            email: 'jenny.wilson@demo.com',
            address: '8502 Preston Rd. Inglewood, Maine'
        },
        {
            imgSrc: SellerImg2,
            name: 'Esther Howard',
            mobile: '(239) 555-0108',
            email: 'esther.howard@demo.com',
            address: '3891 Ranchview Dr. Richardson, CA'
        },
        {
            imgSrc: SellerImg3,
            name: 'Darlene Robertson',
            mobile: '(239) 555-0108',
            email: 'darlene.r@demo.com',
            address: '2972 Westheimer Rd. Santa Ana,'
        },
        {
            imgSrc: SellerImg3,
            name: 'Darlene Robertson',
            mobile: '(239) 555-0108',
            email: 'darlene.r@demo.com',
            address: '2972 Westheimer Rd. Santa Ana,'
        },
    ];
    

    useEffect(() => {
        app.startLoader();
        setTimeout(() => {
            app.stopLoader();
        }, 3000);
    }, [auth.user]);

    return ( 
        <section className="overflow-x-hidden h-[100vh] w-full flex relative">
            <LogoSidebar isSidebarOpen={isSidebarOpen}/>
            <div className="w-full">
                <AdminNavbar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} token={!!auth.user?.token} />
                <div className="w-full min-h-[calc(100%-5rem)] mt-[2rem] lg:pt-[5rem] lg:px-0 macScreens:px-[2rem] lg:pb-5 macScreens:pb-[5rem] flex flex-col gap-10">
                    <div className="flex flex-col windowScreen:gap-7 lg:gap-5 px-5">
                        <div className="w-full flex justify-between">
                            <div className="flex gap-2 items-center">
                                <i class='bx bx-arrow-back lg:text-lg windowScreen:text-2xl' onClick={goBack}></i>
                                <h1 className="font-semibold lg:text-lg windowScreen:text-2xl text-[#000]">Add Product</h1>
                            </div>
                            <div className="flex lg:flex-col xl:flex-row lg:gap-2 xl:gap-5 justify-end lg:w-[50%] xl:w-[70%]">
                                <CustomButton buttonText="Set Commission Percentage" btnClassName="bg-transparent border-2 border-[#00753E] flex items-center text-[#00753E] lg:text-sm xl:text-sm flex justify-center rounded-[5px] hover:text-white"/>
                                <CustomButton buttonText="Deactivate user" btnClassName="bg-[#D60606] text-[#FBFBFB] rounded-[5px] lg:text-sm xl:text-sm flex justify-center"/>
                            </div>
                        </div>
                        <AdminProfileBoard
                        avatarImg={<img className="w-full" src={AgentImg1} alt="" />}
                        avatarSize="w-[30%] md:w-[50%]"
                        title="Smith Roland"
                        AgentNo="+232 4334 2343"
                        className="no-underline"
                        AgentEmail="Rolandsmith@gmail.com"
                        website="(www.BMN.smithroland.12345)"
                        StoreInfoClass="w-full grid grid-cols-1 lg:grid-cols-2 gap-y-10 lg:gap-y-0 bg-[#FBFBFB] xl:px-10 lg:px-2 py-8"
                        DateJoined="March 23, 1995"
                        RegisteredSeller="130"
                        TotalCommission="₦150,000"
                        ActionButton={
                            <CustomButton buttonText="Start Chat" btnClassName="border-[1px] border-[#00753E] text-[#00753E] hover:bg-transparent flex items-center lg:pe-10 gap-3 lg:text-sm xl:text-sm relative" icon={<i class='bx bx-message-alt-detail absolute right-5'></i>}/>
                        }
                        />
                        <div className="flex flex-col gap-2">
                            <p className="text-[#161616] font-semibold lg:text-sm windowScreen:text-xl">Registered Sellers</p>
                            <div className="col-span-2">
                                <DynamicTableForAdmin 
                                name="Seller's name"
                                showCheckbox={true}
                                tableIcon1={
                                    <button
                                    className="text-primary dark:text-primary-400"
                                    data-twe-toggle="tooltip"
                                    title="View seller"
                                    ><span className="material-symbols-outlined lg:text-[1.2rem] windowScreen:text-[1.8rem] text-[#161616] cursor-pointer border-[1px] border-[#E8E8E8] py-1 px-1 rounded-[5px]">visibility</span>
                                    </button>
                                }
                                data={sellersData} 
                                />
                                <div className="flex justify-end w-full pb-[5rem]">
                                    <Pagination />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
     );
}
 
export default AdminAgentProfileII;