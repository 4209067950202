import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import CustomButton from "./CustomButton"; // Assuming you have this component
import SellerLogo from "../assets/seller-logo.png";

const JobCard = ({ job }) => {
  const navigate = useNavigate(); // Initialize useNavigate

  const handleApplyClick = () => {
    // Programmatically navigate to /apply-job/:id
    navigate(`/apply-job/${job.id}`);
  };

  return (
    <div className="job-card bg-white shadow-md p-5 rounded-lg flex flex-col justify-between">
      {/* Company Image and Name */}
      <div className="flex items-center mb-4">
        {job.companyImage && (
          <img
            src={ SellerLogo}
            alt={job.employer}
            className="w-12 h-12 object-cover rounded-full mr-4"
          />
        )}
        <img
            src={ SellerLogo}
            alt={job.employer}
            className="w-12 h-12 object-cover rounded-full mr-4"
          />
        <h3 className="font-semibold text-lg">{job.employer}</h3>
      </div>

 
      <h4 className="font-semibold text-gray-900 text-lg mb-2">{job.jobTitle}</h4>
      <p className="text-gray-500 mb-4">{job.location}</p>
      <p className="text-gray-700 mb-6">{job.description}</p>

      <div className="mt-auto">
        <CustomButton
          buttonText="Apply Now"
          btnClassName="bg-[#104E30] text-white py-2 px-4 rounded"
          onClick={handleApplyClick}
        />
      </div>
    </div>
  );
};

export default JobCard;
