import React, { useState } from 'react';
import Modal from './Modal';
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import { appStore, authStore } from '../store';
import { sellerCreateProduct } from '../api';
import { toastSuccess, toastError } from "../utils/constants";
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const SubscriptionPlan = ({ plan,productData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const auth = authStore(state => state);
  const app = appStore(state => state);
  const navigate = useNavigate()

  const config = {
    public_key: "FLWPUBK_TEST-38c151bd4006168353f90aa25ffd52dd-X",
    tx_ref: Date.now(),
    amount: parseFloat(plan.price.replace(/,/g, '')),
    currency: "NGN",
    payment_options: "card,mobilemoney,ussd",
    customer: {
      email: auth.user.email,
      // phone_number: phone,
      name: `${auth.user.firstName} ${auth.user.lastName}`,
    },
    customizations: {
      title: "BuymeNaija Plan Subscription",
      description: plan.description,
      logo: "https://buymenaija.com/img/Logo.png",
    },
  };

  const handleFlutterPayment = useFlutterwave(config);

  const handleSubscription = () => {
    try {
       sellerCreateProduct(
        {
          userId: auth.user.userId,
          name: productData.name,
          basePrice: parseInt(productData.price),
          description: productData.longDescription,
          shortDescription: productData.shortDescription,
          longDescription: productData.longDescription,
          picture: productData.productMedia,
          categories: [productData.productCategory],
          specifications: productData.specifications,
        },
        { Authorization: `Bearer ${auth.user.token}` }
      );
  
      // toast('Product Created', toastSuccess);

      // setTimeout(() => {
      //   navigate('/'); // Navigate to the root route
      // }, 1000);
    } catch (e) {
      console.error('Error creating product:', e); // Log the error to the console
      toast(e.message, toastError);
    } finally {
      app.stopLoader();
    }
  };
  

  return (
    <div className={`flex flex-col rounded-lg p-6 text-center bg-white gap-8 shadow-custom-light`}>
      <h4 className="text-lg font-semibold">{plan.duration}</h4>
      <p className="text-2xl font-bold my-2">{`₦${(plan.price)}`}</p>
      <p className="text-gray-500 font-normal">{plan.description}</p>
      {plan.isCurrent ? (
        <button onClick={openModal} className="mt-4 bg-green-600 text-white py-2 px-4 rounded">View Plan</button>
      ) : (
        <button onClick={()=>


          handleFlutterPayment({
            callback: 
            (response) => {
              console.log(response);
              sellerCreateProduct(
                {
                  userId: auth.user.userId,
                  name: productData.name,
                  basePrice: parseInt(productData.price),
                  description: productData.longDescription,
                  shortDescription: productData.shortDescription,
                  longDescription: productData.longDescription,
                  picture: productData.productMedia,
                  categories: [productData.productCategory],
                  specifications: productData.specifications,
                },
                { Authorization: `Bearer ${auth.user.token}` }
              );
              toast('Product Created', toastSuccess);
           
      // Navigate to the root route
   
              closePaymentModal();
              navigate('/');
              console.log(`navigated`)


            },
            onClose: () => {
              
            },
          
          })
          
        } className="mt-4 bg-green-600 text-white py-2 px-4 rounded">Buy Plan</button>
      )}

      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <h2 className="text-2xl font-bold mb-4">{plan.isCurrent ? 'Your Current Plan' : 'Buy This Plan'}</h2>
        <p>{plan.description}</p>
        <button onClick={closeModal} className="mt-4 bg-green-600 text-white py-2 px-4 rounded">
          Close
        </button>
      </Modal>
    </div>
  );
};

export default SubscriptionPlan;
