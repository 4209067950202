import { useState, useEffect } from "react";
import { appStore, authStore } from "../../store";
import Footer from "../../components/Footer";
import BuyerPathBreadCrumb from "../../components/BuyerPath";
import Navbar from "../../components/Navbar";
import VerticalTabs from "./VerticalTabs";
import { useParams, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { toastError } from "../../utils/constants";
import ProductTable from "../../components/SellerProductTable";
import { groupIntoChunks } from "../../utils/helpers";
import { getProductById } from "../../api";

const SellerViewProducts = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const auth = authStore(state => state);
    const app = appStore(state => state);
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // useEffect(() => {
    //     window.scrollTo(0, 0);
    //     setTimeout(() => {
    //         app.stopLoader();
    //     }, 3000);
    // }, [auth.user, app]);

    useEffect(() => {
        window.scrollTo(0, 0);

        const fetchProduct = async () => {
            try {
                setLoading(true);
                const fetchedProduct = await getProductById(id);
                setProduct(fetchedProduct);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchProduct();
    }, [id]);

    if (!id) {
        navigate(-1);
        toast.error("Product not found", toastError);
        return null;
    }

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!product) {
        return <div>Product not found</div>;
    }

    const productSpecData = product.specifications.map(spec => ({ label: spec.name, value: spec.value }));
    console.log(productSpecData);
    const groupedData = groupIntoChunks(productSpecData, 3);

    return (
        <section className="overflow-x-hidden w-full flex flex-col items-center macScreens:gap-5 lg:gap-3 gap-2">
            <Navbar token={!!auth.user?.token} role={auth.role} />
            <div className="relative md:w-[90%] w-[95%] flex flex-col justify-center items-center gap-10">
                <BuyerPathBreadCrumb home="Home" shop1="> Products" shop=">  View Products" />
            </div>
            <VerticalTabs contentComponent={
                <div className="flex flex-col gap-4 h-full">
                    <div className="flex gap-2 items-center">
                        <i className='bx bx-arrow-back text-[2rem]' onClick={() => navigate(-1)}></i>
                        <h1 className="font-semibold text-[1.5rem] text-[#000]">View Product</h1>
                    </div>
                    <div className="flex justify-center w-full h-full">
                        <div className="w-full flex flex-col gap-5">
                            <div className="pb-7">
                                <ProductTable productData={{ specifications: groupedData, product: product }} />
                            </div>
                        </div>
                    </div>
                </div>
            } />
            <Footer />
        </section>
    );
}

export default SellerViewProducts;
