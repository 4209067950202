import React from 'react';

const JobDetailsCard = ({ title, employer, remuneration, jobType, description, postedTime }) => {
  return (
    <div className="job-details-card bg-white">
      {/* Job Title */}
      <h2 className="font-semibold text-2xl mb-3 text-gray-800">{title}</h2>
      
      {/* Employer */}
      <p className="text-lg text-gray-600 mb-1">
        <span className="font-semibold">Employer: </span>{employer}
      </p>
      
      {/* Remuneration */}
      <p className="text-lg text-gray-600 mb-1">
        <span className="font-semibold">Salary: </span>{remuneration}
      </p>
      
      {/* Job Type */}
      <p className="text-lg text-gray-600 mb-1">
        <span className="font-semibold">Job Type: </span>{jobType}
      </p>
      
      {/* Posted Time */}
      <p className="text-sm text-gray-400 mb-4">
        <span className="font-semibold">Posted: </span>{postedTime}
      </p>
      
      {/* Job Description */}
      <div>
        <h3 className="font-semibold text-lg text-gray-700 mb-2">Job Description</h3>
        <p className="text-gray-700">{description}</p>
      </div>
    </div>
  );
};

export default JobDetailsCard;
