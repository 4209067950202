import DashboardProfileHeader from "./DashboardProfileHeader";
import ProfileImg1 from '../assets/dp.jpeg';
import { appStore, authStore } from "../store";
import { useState, useEffect } from "react";
import { getUserDetails } from "../api";

const AdminNavbar = ({ navbarClassName, toggleSidebar }) => {
  const auth = authStore(state => state);
  const app = appStore(state => state);
  const fullname = `${auth.user.firstName} ${auth.user.lastName}`;
  const dp = auth.user?.profileImage || ProfileImg1;
  const [adminInfo, setAdminInfo] = useState([{ fullname: 'ade' }]);

  const fetchData = async () => {
    await getUserDetails({ Authorization: `Bearer ${auth.user.token}` })
      .then(response => {
        setAdminInfo([{ fullname: response.firstName + ' ' + response.lastName }]);
      })
      .catch(e => {
        if (e.message === 'jwt expired') {
          auth.logout();
        }
      })
      .finally(() => {
        app.stopLoader();
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <nav className={`navbar flex justify-between items-center pb-2 pt-5 shadow-md bg-[#00753E] fixed top-0 z-30 w-full h-[5rem] px-4 ${navbarClassName}`}>
      <button
        type="button"
        className="md:hidden"
        onClick={() => toggleSidebar()}
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-white">
          <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
        </svg>
      </button>
      <div className="">
        <DashboardProfileHeader
          AcctImg={dp}
          AcctType="Admin"
          AcctName={adminInfo[0].fullname || "Davies Grant"} 
          HeaderClass="w-full md:h-[4rem] flex justify-end items-center gap-3 lg:gap-7 " 
        />
      </div>
    </nav>
  );
}

export default AdminNavbar;
