import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { authStore, appStore } from "../store";
import { adminGetAllCategory as getAllCategories } from "../api";

const FooterComponents = () => {
    const [Categories, setCategories] = useState([]);

    const auth = authStore(state => state);
    const app = appStore(state => state);

    const fetchCategoryData = async () => {
        app.startLoader();
        await getAllCategories(null).then(async response => {
            setCategories(response);
            auth.setCategories(response);
        }).catch(e => {
            console.log(e.message);
        }).finally(() => {
            app.stopLoader();
        });
    };

    useEffect(() => {
        fetchCategoryData();
    }, []);

    return (
        <div className="w-[95%] md:w-[90%] lg:w-[75%] xl:w-[70%] grid grid-cols-1 gap-y-10 md:grid-cols-3 md:gap-x-[5rem]">
            <div className="flex flex-col gap-5 px-5 md:px-0">
                <h1 className="text-white font-bold text-[1rem] md:text-[1.5rem]">Get In Touch</h1>
                <p className="font-Nunito text-white text-[.8rem] md:text-[.8rem] lg:text-[1rem]">
  Discover a world of opportunities with buymenaija. <br /> 
  Buy and sell with ease, <br /> 
  and connect with a vibrant marketplace community.
</p>
 <div className="flex items-center gap-3 text-white text-[1.5rem]">
                    <NavLink className="hover:text-[#0c2218] hover:transition-all hover:duration-500" to="#"><i className='bx bxl-facebook-circle' ></i></NavLink>
                    <NavLink className="hover:text-[#0c2218] hover:transition-all hover:duration-500" to="#"><i className='bx bxl-instagram' ></i></NavLink>
                    <NavLink className="hover:text-[#0c2218] hover:transition-all hover:duration-500" to="#"><i className='bx bxl-twitter' ></i></NavLink>
                </div>
            </div>
            <div className="hidden md:flex flex-col items-start gap-5 text-white">
                <h1 className="font-bold text-[1rem] md:text-[1.5rem]">Company info</h1>
                <NavLink className="hover:text-[#0c2218] hover:transition-all hover:duration-500 text-[.8rem] md:text-[.8rem] lg:text-[1rem]" to="/about-us">About Us</NavLink>
                
                {!auth.user?.token && (
                    <>
                        <NavLink className="hover:text-[#0c2218] hover:transition-all hover:duration-500 text-[.8rem] md:text-[.8rem] lg:text-[1rem]" to="/seller/auth/sign-up">
                            Become a Seller
                        </NavLink>
                        <NavLink className="hover:text-[#0c2218] hover:transition-all hover:duration-500 text-[.8rem] md:text-[.8rem] lg:text-[1rem]" to="/agent/auth/sign-up">
                            Become an Agent
                        </NavLink>

                <NavLink className="hover:text-[#0c2218] hover:transition-all hover:duration-500 text-[.8rem] md:text-[.8rem] lg:text-[1rem]" to="/apply-job">
                                Apply for job
                            </NavLink>
                        
                    </>
                )}
            </div>
            <div className="hidden md:flex flex-col items-start gap-5 text-white">
                <h1 className="font-bold text-[1rem] md:text-[1.5rem]">Categories</h1>
                {Categories.map((category) => (
                    <NavLink className="hover:text-[#0c2218] hover:transition-all hover:duration-500 text-[.8rem] md:text-[.8rem] lg:text-[1rem]" to={`/categories/${category.id}`} key={category.id}>{category.name}</NavLink>
                ))}
            </div>
            <div className="md:hidden grid grid-cols-2 px-5">
                <div className="flex flex-col items-start gap-2 md:gap-5 text-white">
                    <h1 className="font-bold text-[1rem] md:text-[1.5rem]">Company info</h1>
                    <NavLink className="hover:text-[#0c2218] hover:transition-all hover:duration-500 text-[.8rem] md:text-[.8rem] lg:text-[1rem]" to="/about-us">About Us</NavLink>
                    {!auth.user?.token && (
                        <>
                            <NavLink className="hover:text-[#0c2218] hover:transition-all hover:duration-500 text-[.8rem] md:text-[.8rem] lg:text-[1rem]" to="/seller/auth/sign-up">
                                Become a Seller
                            </NavLink>
                            <NavLink className="hover:text-[#0c2218] hover:transition-all hover:duration-500 text-[.8rem] md:text-[.8rem] lg:text-[1rem]" to="/agent/auth/sign-up">
                                Become an Agent
                            </NavLink>
                            <NavLink className="hover:text-[#0c2218] hover:transition-all hover:duration-500 text-[.8rem] md:text-[.8rem] lg:text-[1rem]" to="/apply-job">
                                Apply for job
                            </NavLink>
                        </>
                    )}
                </div>
                <div className="flex flex-col items-start gap-2 md:gap-5 text-white">
                    <h1 className="font-bold text-[1rem] md:text-[1.5rem]">Categories</h1>
                    {Categories.map((category) => (
                        <NavLink className="hover:text-[#0c2218] hover:transition-all hover:duration-500 text-[.8rem] md:text-[.8rem] lg:text-[1rem]" to={`/categories/${category.id}`} key={category.id}>{category.name}</NavLink>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default FooterComponents;
