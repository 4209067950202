import { useEffect, useState } from "react";
import { appStore, authStore } from "../../store";
import NavbarDashboard from "../../components/NavbarDashboard";
import LogoHome from "../../components/Logo";
import SidebarNavLink from "../../components/SidebarNavLink";
import TotalCommissionImg from "../../assets/total-commission.png";
import TotalSellersImg from "../../assets/total-sellers.png";
import SellerImg1 from "../../assets/seller-img-1.png";
import SellerImg2 from "../../assets/seller-img-2.png";
import SellerImg3 from "../../assets/seller-img-3.png";
import DynamicTableForAgent from "../../components/DynamicTableForAgent";
import CustomButton from "../../components/CustomButton";
import { NavLink } from "react-router-dom";
import ChartComponent from "../../components/ChartComponent";
import AgentSideBar from "../../components/AgentSidebar";
import { getUserByAgent } from "../../api";
import Pagination from "../../components/Pagination";
import AgentSideBarContainer from "../../components/AgentSideBarContainer";

const AgentDashboard = () => {
    const [isSidebarOpen, setSidebarOpen] = useState(false);
    const auth = authStore(state => state);
    const app = appStore(state => state);
    const [sellr, setSellr] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const totalPages = Math.ceil(sellr.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = sellr.slice(indexOfFirstItem, indexOfLastItem);

    const fetchData = async () => {
        await getUserByAgent({ Authorization: `Bearer ${auth.user.token}` }).then(response => {
            
            const filteredData = response.filter(item => item.referralId === auth.user.referralId

                && item.email !==auth.user.email
            );
            
            setSellr(filteredData);
            console.log(response);
        }).catch(e => {
            console.log(e.message);
            if (e.message === 'jwt expired') {
                auth.logout();
            }
        }).finally(() => {
            app.stopLoader();
        });
    };

    useEffect(() => {
        app.startLoader();
        fetchData();
        setTimeout(() => {
            app.stopLoader();
        }, 3000);
    }, [auth.user]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
    };

    return (
        <section className="overflow-x-hidden h-[100vh] w-full flex relative">
        <AgentSideBarContainer isSidebarOpen={isSidebarOpen} />
        <div className="w-full">
            <NavbarDashboard isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} token={!!auth.user?.token} />
            <div className="w-full min-h-[calc(100%-5rem)] pt-[6rem] px-4 pb-[5rem] mt-[2rem] lg:pt-[5rem] lg:px-2 macScreens:px-[2rem] lg:pb-5 macScreens:pb-[5rem] grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-2">
                <div className="order-1 lg:order-none flex flex-col gap-5">
                    <div className="text-center lg:text-left">
                        <h1 className="text-[#110D06] text-xl lg:text-2xl macScreens:text-3xl font-semibold">Good Morning, {auth.user.firstName} 👋 </h1>
                        <p className="text-[#A1A1A1] text-sm lg:text-base macScreens:text-lg font-Nunito font-light">Welcome to BuyMe₦aija, Manage your activities as an agent.</p>
                    </div>

                    <div className="border-[1px] border-[#EDEDED] rounded-[10px] p-4 lg:p-6 flex flex-col items-start">
                        <div className="flex items-center gap-4 mb-2">
                            <img className="w-8 h-8 lg:w-10 lg:h-10" src={TotalCommissionImg} alt="Total Commission" />
                            <p className="font-Nunito text-[#161616] text-sm lg:text-base">Total Commission</p>
                        </div>
                        <h1 className="text-[#161616] text-2xl lg:text-3xl macScreens:text-4xl font-semibold">₦ 0</h1>
                        <div className="border-t-[1px] border-[#EDEDED] text-[#A1A1A1] text-xs lg:text-sm font-Nunito font-light w-full mt-2 pt-2 text-center lg:text-left">Update: October 20, 2023</div>
                    </div>

                    <div className="border-[1px] border-[#EDEDED] rounded-[10px] p-4 lg:p-6 flex flex-col items-start">
                        <div className="flex items-center gap-4 mb-2">
                            <img className="w-8 h-8 lg:w-10 lg:h-10" src={TotalSellersImg} alt="Total Sellers" />
                            <p className="font-Nunito text-[#161616] text-sm lg:text-base">Total Sellers</p>
                        </div>
                        <h1 className="text-[#161616] text-2xl lg:text-3xl macScreens:text-4xl font-semibold">{sellr.length}</h1>
                        <div className="border-t-[1px] border-[#EDEDED] text-[#A1A1A1] text-xs lg:text-sm font-Nunito font-light w-full mt-2 pt-2 text-center lg:text-left">Last date of registration: October 20, 2023</div>
                    </div>
                </div>

                {/* Chart Section */}
                <div className="order-2 lg:order-none border-[1px] border-[#EDEDED] rounded-[10px] p-4 lg:p-6">
                    <ChartComponent />
                </div>

                {/* Table Section */}
                <div className="order-3 col-span-1 lg:col-span-2 flex flex-col gap-6 lg:gap-10">
                    <NavLink to="/seller/my-products" className="flex justify-end lg:mt-10 macScreens:mt-[5rem]">
                        <CustomButton buttonText="View All" btnClassName="bg-transparent text-[#00753E] rounded-[15px] border-[1px] border-[#00753E] hover:bg-transparent" />
                    </NavLink>
                    <DynamicTableForAgent
                        name="Seller's Name"
                        showCheckbox={false}
                        tableIcon2={
                            <NavLink
                                to="/admin/seller-profile"
                                className="text-primary dark:text-primary-400"
                                data-twe-toggle="tooltip"
                                title="View seller"
                            >
                                <span className="material-symbols-outlined text-[1.2rem] lg:text-[1.8rem] text-[#161616] cursor-pointer border-[1px] border-[#E8E8E8] py-1 px-1 rounded-[5px]">visibility</span>
                            </NavLink>
                        }
                        tableIcon3={
                            <button
                                className="text-primary dark:text-primary-400"
                                data-twe-toggle="tooltip"
                                title="Deactivate seller"
                            >
                                <i className='bx bx-trash cursor-pointer border-[1px] border-[#E8E8E8] py-2 px-2 rounded-[5px] text-[#161616] text-[.8rem] lg:text-[1.4rem]'></i>
                            </button>
                        }
                        data={currentItems}
                    />
                    <div className="flex justify-end w-full">
                        <Pagination
                            activePage={currentPage}
                            totalPages={totalPages}
                            onPageChange={handlePageChange}
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>
    );
}

export default AgentDashboard;
