import { useState,useEffect } from "react";
import { appStore, authStore } from "../../store";
import LogoHome from "../../components/Logo";
import SidebarNavLink from "../../components/SidebarNavLink";
import InputField from "../../components/InputFieldComponents";
import CustomButton from "../../components/CustomButton";
import AdminNavbar from "../../components/AdminNavbar";
import LogoSidebar from "../../components/LogoSidebar";

const AdminEditProfile = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
    const auth = authStore(state => state);
    const app = appStore(state => state);
    

    useEffect(() => {
        app.startLoader();
        setTimeout(() => {
            app.stopLoader();
        }, 3000);
    }, [auth.user]);

    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
      };
    return ( 
        <section className="overflow-x-hidden h-[100vh] w-full flex relative">
            <LogoSidebar isSidebarOpen={isSidebarOpen}/>
            <div className="w-full">
                <AdminNavbar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} token={!!auth.user?.token} />
                <div className="w-full min-h-[calc(100%-5rem)] mt-[2rem] lg:pt-[5rem] lg:px-0 macScreens:px-[2rem] lg:pb-5 macScreens:pb-[5rem] flex flex-col gap-10">
                    <div className="flex flex-col windowScreen:gap-10 gap-5 px-5">
                        <h1 className="font-semibold text-[#110D06] lg:text-xl macScreens:text-xl">Edit Profile</h1>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-7 w-full border rounded-[10px] px-7 py-7">
                            <div className="input-box flex flex-col gap-1 lg:gap-2 macScreens:gap-2  macScreens:text-[1rem] lg:text-[.8rem]">
                                <InputField label="First Name" name="first-name" value="Smith" type="text" required onChange={(e) => console.log(e)} />
                            </div>
                            <div className="input-box flex flex-col gap-1 lg:gap-2 macScreens:gap-2  macScreens:text-[1rem] lg:text-[.8rem]">
                                <InputField label="Last Name" name="last-name" value="Roland" type="text" required onChange={(e) => console.log(e)} />
                            </div>
                            <div className="input-box flex flex-col gap-1 lg:gap-2 macScreens:gap-2  macScreens:text-[1rem] lg:text-[.8rem]">
                                <InputField label="Email Address" name="email" value="Rolandsmit@gmail.com" type="email" required onChange={(e) => console.log(e)} />
                            </div>
                            <div className="input-box flex flex-col gap-1 lg:gap-2 macScreens:gap-2  macScreens:text-[1rem] lg:text-[.8rem]">
                                <InputField label="Phone Number" name="phone" value="+232 4334 2343" type="tel" required onChange={(e) => console.log(e)} />
                            </div>
                            <div className="flex flex-col gap-y-10 w-full col-span-2">
                                <div className="input-box flex flex-col gap-1 lg:gap-2 macScreens:gap-2  macScreens:text-[1rem] lg:text-[.8rem] lg:w-[40%] 2xl:w-[20%]">
                                    <CustomButton buttonText="Save Changes" btnClassName="bg-[#00753E] text-[#FBFBFB] rounded-[5px]"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
     );
}
 
export default AdminEditProfile;