import React from 'react';
import SubscriptionPlan from './SubscriptionPlan';
import { appStore, authStore } from "../store";
import { sellerCreateProduct } from "../api";
import { toastSuccess, toastError } from "../utils/constants";
import toast from 'react-hot-toast';
import { Navigate } from 'react-router-dom';



const currentPlan = {
  duration: "3 Months",
  price: "8,000",
  description: "Informational or Small Business Website (8-16 pages)",
  isCurrent: true,
};

const otherPlans = [
  {
    duration: "1 Month",
    price: "3,000",
    description: "Informational or Small Business Website (8-16 pages)",
  },
  {
    duration: "3 Months",
    price: "8,000",
    description: "Informational or Small Business Website (8-16 pages)",
  },
  {
    duration: "6 Months",
    price: "15,000",
    description: "Informational or Small Business Website (8-16 pages)",
  },
];

const SubscriptionPlans = ({productData, uris}) => {
  const auth = authStore(state => state);
  const app = appStore(state => state);

  const hasCurrentPlan = currentPlan && Object.keys(currentPlan).length > 0;
  const hasOtherPlans = otherPlans && otherPlans.length > 0;

  console.log(`product Data  ${
    JSON.stringify(productData)
  }`)
  

  const handleSubscription = async (data) => {
    try {
        await sellerCreateProduct({
            userId: auth.user.userId,
            name: data.name,
            basePrice: parseInt(data.price),
            description: data.longDescription,
            shortDescription: data.shortDescription,
            longDescription: data.longDescription,
            picture: uris,
            categories: [data.productCategory],
            specifications: data.specifications,
        }, { Authorization: `Bearer ${auth.user.token}` });

        toast('Product Created', toastSuccess);
        setTimeout(() => {
            Navigate(-1);
        }, 1000);
    } catch (e) {
        toast(e.message, toastError);
    } finally {
        app.stopLoader();
    }
  };
  return (
    <div className="p-6">
      <h2 className="text-2xl font-semibold text-center">Subscription Plans</h2>
      <p className="mt-2 text-gray-500 font-normal text-center">
  Choose the perfect plan for you: <br />
  Enjoy exclusive features and benefits with our flexible subscription options. <br />
  Whether you're looking for basic access or premium perks, we have a plan that fits your needs.
</p>

      <div className="mt-6 grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* {hasCurrentPlan && (
          <div className="grid grid-cols-1">
            <h3 className="text-xl font-semibold">Current Subscription Plan</h3>
            <SubscriptionPlan plan={currentPlan} />
          </div>
        )} */}
        {hasOtherPlans && (
          <div className={`lg:col-span-3 grid grid-cols-1 md:grid-cols-3 gap-4`}>
            {hasCurrentPlan && (
              <h3 className="text-xl font-semibold col-span-1 md:col-span-3">Other Subscriptions Plans</h3>
            )}
            {otherPlans.map((plan, index) => (
              <SubscriptionPlan key={index} plan={plan} 
              
              productData={productData}
              
              onClick={() => handleSubscription(plan)}/>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SubscriptionPlans;
