import React, {useState} from 'react';
import { appStore } from '../store';



const CustomCheckBox = ({data, onChange}) => {
    const [checked, setChecked] = useState(data.isActive);

    const handleChange = () => {
        setChecked(!checked);
        // onChange({ id: data.id, status: !checked, name: data.name });
    }

    return (
        <label class="switch">
            <input type="checkbox" checked={checked} onChange={handleChange} />
            <span class="slider round"></span>
        </label>
    );
}

const JobDynamicTable = ({ data, tableIcon1, tableIcon2, tableIcon3, toggledChecked, clickedToEdit }) => {
    return (
        <table className="w-full bg-transparent rounded-[10px]">
            <thead>
                <tr>
                    <th className="text-start lg:text-[.6rem] windowScreen:text-sm font-medium text-[#161616] border-b-[1px] border-b-[#E8E8E8] py-4">
                        <div className="flex items-center ps-[50px]">
                            <p className="">Job Title</p>
                        </div>
                    </th>
                    <th className="text-start lg:text-[.6rem] windowScreen:text-sm font-medium text-[#161616] border-b-[1px] border-b-[#E8E8E8] py-4">
                        <div className="flex items-center ps-[50px]">
                            <p className="">Employer</p>
                        </div>
                    </th>
                    <th className="text-start lg:text-[.6rem] windowScreen:text-sm font-medium text-[#161616] border-b-[1px] border-b-[#E8E8E8] py-4">
                        <div className="flex items-center ps-[50px]">
                            <p className="">Job Type</p>
                        </div>
                    </th>
                  
                    <th className="text-start lg:text-[.6rem] windowScreen:text-sm font-medium text-[#161616] border-b-[1px] border-b-[#E8E8E8] py-4">
                        <div className="flex items-center ps-[50px]">
                            <p className="">Renumeration</p>
                        </div>
                    </th>

                    <th className="text-start lg:text-[.6rem] windowScreen:text-sm font-medium text-[#161616] border-b-[1px] border-b-[#E8E8E8] py-4">Status</th>
                    {/* <th className="text-start lg:text-[.6rem] windowScreen:text-sm font-medium text-[#161616] border-b-[1px] border-b-[#E8E8E8] py-4">Action</th> */}
                </tr>
            </thead>
            <tbody>
                {data.map((dataValue, index) => (
                    <tr key={index}>
                        <td className="py-4 px-2 border-b-[1px] border-b-[#E8E8E8] text-[#161616] lg:text-[.6rem] windowScreen:text-sm font-Nunito font-light">
                            <div className="flex items-center gap-10">
                               <div className="">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="6" viewBox="0 0 12 6" fill="none">
                                        <path d="M1 1H11M1 5H11" stroke="#161616" stroke-width="1.5" stroke-linecap="round"/>
                                    </svg>
                               </div>
                                <div className="flex gap-2 items-center">
                                    <p className="">{dataValue.jobTitle}</p>
                                </div>
                            </div>
                        </td>
                        <td className="py-4 px-2 border-b-[1px] border-b-[#E8E8E8] text-[#161616] lg:text-[.6rem] windowScreen:text-sm font-Nunito font-light">
                        <div className="flex items-center gap-10">
                              
                                <div className="flex gap-2 items-center">
                                    <p className="">{dataValue.employer}</p>
                                </div>
                            </div>
                            {/* <CustomCheckBox data={dataValue} onChange={(data) => toggledChecked(data)} /> */}
                        </td>

                        <td className="py-4 px-2 border-b-[1px] border-b-[#E8E8E8] text-[#161616] lg:text-[.6rem] windowScreen:text-sm font-Nunito font-light">
                        <div className="flex items-center gap-10">
                              
                                <div className="flex gap-2 items-center">
                                    <p className="">{dataValue.jobType}</p>
                                </div>
                            </div>
                          
                        </td>

                        <td className="py-4 px-2 border-b-[1px] border-b-[#E8E8E8] text-[#161616] lg:text-[.6rem] windowScreen:text-sm font-Nunito font-light">
                        <div className="flex items-center gap-10">
                              
                                <div className="flex gap-2 items-center">
                                    <p className="">{dataValue.remuneration}</p>
                                </div>
                            </div>
                          
                        </td>

                        {/* <td className="py-4 px-2 border-b-[1px] border-b-[#E8E8E8] text-[#161616] lg:text-[.6rem] windowScreen:text-sm font-Nunito font-light">
                        <div className="flex items-center gap-10">
                              
                                <div className="flex gap-2 items-center">
                                    <p className="">{dataValue.isActive}</p>
                                </div>
                            </div>
                          
                        </td> */}
                        
                        <td className="py-4 px-2 border-b-[1px] border-b-[#E8E8E8] text-[#161616] lg:text-[.6rem] windowScreen:text-sm font-Nunito font-light">
                          <CustomCheckBox data={dataValue} onChange={(data) => toggledChecked(data)} />
                      
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default JobDynamicTable;