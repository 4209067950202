import React, { useEffect, useState } from "react";
import { authStore } from "../store";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import BuyerPathBreadCrumb from "../components/BuyerPath";
import SimilarAds from "../components/SimilarAds";
import Pagination from "../components/Pagination";
import { useParams } from "react-router-dom";
import { getSellersByMarket } from "../api"; // Adjust the import to your actual API call
import SimilarSeller from "../components/SimilarSeller";

const ProductSeller = () => {
    const [sellers, setSellers] = useState([]);
    const [loading, setLoading] = useState(true);
    const auth = authStore(state => state);
    const { id } = useParams();


    useEffect(() => {
        window.scrollTo(0, 0);
        fetchSellers();
    }, [id]);

    const fetchSellers = async () => {
        setLoading(true);
        try {
            const response = await getSellersByMarket(id);
            setSellers(response);
        } catch (error) {
            console.error("Failed to fetch sellers:", error.message);
        } finally {
            setLoading(false);
        }
    };



    return (
        <section className="overflow-x-hidden w-full flex flex-col items-center macScreens:gap-5 lg:gap-3 gap-2">
            <Navbar token={!!auth.user?.token} />
            <div className="relative md:w-[90%] w-[95%] flex flex-col justify-center items-center gap-10 mt-[10rem] md:mt-[7rem]">
                <BuyerPathBreadCrumb home="Home >" shop1="Market" shop="> Seller's store"/>
            </div>

            {loading ? (
                <p>Loading sellers...</p>
            ) : (
                <>
                    <SimilarSeller 
                        className="border-b-0" 
                        HeaderText={`Seller from ${sellers.name}`} 
                        showComponent={true} 
                        showClearButton={false} 
                        sellers={sellers}
                    />
                    <div className="max:w-full px-7 pb-10 md:pb-[2rem] mt-[-2rem] md:mt-[-5rem] lg:mt-[-10rem] md:ml-auto flex justify-center md:justify-end items-center pe-5 md:pe-20">
                        <Pagination />
                    </div>
                </>
            )}

            <Footer />
        </section>
    );
};

export default ProductSeller;
