import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import JobDetailsCard from "../components/JobDetailsCard";
import SellerImage from '../assets/seller-logo.png';
import toast from 'react-hot-toast';
import { getJobById, submitJobApplication } from "../api";
import api from "../api/server";

const JobDescription = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [job, setJob] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [application, setApplication] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    resume: null,
    coverLetter: "",
  });


  const handleFileChange = (e) => {
    // Set the selected files to the application state
    setApplication({ ...application, resume: e.target.files });
  };
  
  const uploadFileCustom = async (file) => {
    try {
      const formData = new FormData();
  
      // Check if a file is selected
      if (file && file[0]) {
        console.log(`file ${JSON.stringify(file[0])}`);
        formData.append('file', file[0]);
  
        // Upload the file using an API call
        const response = await api.post('/product/uploadimage', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
  
        // Return the file URL from the response
        return response.fileUrl;
      } else {
        throw new Error('No file selected');
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      toast.error('Failed to upload the file.');
      return null;
    }
  };
  

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchJob = async () => {
      try {
        setLoading(true);
        const fetchedJob = await getJobById(id);
        setJob(fetchedJob);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchJob();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setApplication({ ...application, [name]: value });
  };



  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!application.name || !application.email || !application.phoneNumber || !application.resume) {
      toast.error("Please fill all required fields.");
      return;
    }

    try {
      const resumeUrl = await uploadFileCustom(application.resume);
      console.log(`resume url ${resumeUrl}`)
      if (!resumeUrl) {
        toast.error("Resume upload failed.");
        return;
      }

      

      await submitJobApplication({
        applicantId:application.email,
        jobVacancyId:id,
        email:application.email,
        phoneNumber:application.phoneNumber
       
      });
      toast.success("Application submitted successfully!");
      setApplication({
        name: "",
        email: "",
        phoneNumber: "",
        resume: null,
        coverLetter: "",
      });

      navigate("/");
    } catch (err) {
      toast.error("Failed to submit application.");
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!job) {
    return <div>Job not found</div>;
  }

  return (
    <section className="overflow-x-hidden w-full">
      <Navbar />
      <div className="md:w-[100%] w-[95%] flex flex-col justify-center items-center gap-10 mt-[8.5rem] md:mt-[6.5rem]">
        <div className="w-full grid grid-cols-2 mb-6 px-2 md:px-10 gap-y-10 lg:gap-x-5">
          <div className="w-full ">
            <img src={SellerImage} alt="Seller Logo" className="w-full" />
          </div>
          <div className="w-full py-20 px-4">
            <JobDetailsCard
              title={job.jobTitle}
              employer={job.employer}
              remuneration={job.remuneration}
              jobType={job.jobType}
              description={job.description}
              postedTime={new Date(job.createdAt).toLocaleDateString()}
            />
          </div>
        </div>

        <div className="w-full flex flex-col items-center gap-6 pb-12">
          <h2 className="text-2xl font-semibold">Apply for this Job</h2>
          <form className="w-full max-w-md bg-white p-6" onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Name:
              </label>
              <input
                type="text"
                name="name"
                value={application.name}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-indigo-500"
                required
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Email:
              </label>
              <input
                type="email"
                name="email"
                value={application.email}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-indigo-500"
                required
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Phone Number:
              </label>
              <input
                type="tel"
                name="phoneNumber"
                value={application.phoneNumber}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-indigo-500"
                required
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Resume (PDF or Word):
              </label>
              <input
  type="file"
  accept=".md,.pdf,image/*"
  onChange={handleFileChange}
  className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-indigo-500"
  required
/>

            </div>

            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Cover Letter (Optional):
              </label>
              <textarea
                name="coverLetter"
                value={application.coverLetter}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-indigo-500"
                rows="4"
              ></textarea>
            </div>

            <button
              type="submit"
              className="w-full bg-[#104E30] text-white py-2 rounded-lg hover:bg-indigo-700 transition"
            >
              Submit Application
            </button>
          </form>
        </div>
      </div>

      <Footer />
    </section>
  );
};

export default JobDescription;
