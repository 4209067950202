import DashboardProfileHeader from "./DashboardProfileHeader";
import ProfileImg1 from '../assets/profile-img-1.png'

const NavbarDashboard = ({navbarClassName, toggleSidebar}) => {
    return ( 
        <nav className={`navbar flex gap-3 md:gap-0 justify-between items-center px-2 pb-2 pt-5 shadow-md fixed top-0 z-30 w-full bg-[#00753E] h-[5rem] overflow-hidden ${navbarClassName}`}>
        <button
        type="button"
        className="md:hidden"
        onClick={() => toggleSidebar()}
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-white">
          <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
        </svg>
      </button>
            <div className="">
                <DashboardProfileHeader AcctImg={ProfileImg1} AcctType="Agent" AcctName="Davies Grant" HeaderClass="w-full md:h-[4rem] flex justify-end items-center gap-3 lg:gap-7" />
            </div>
        </nav>
     );
}
 
export default NavbarDashboard;