import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { appStore, authStore } from "../store";
import SearchBar from "./SearchbarComponent";
import { adminGetAllCategory as getAllCategories } from "../api";

const NavbarSubsection = () => {
    const [Categories, setCategories] = useState([]);
    const auth = authStore(state => state);
    const app = appStore(state => state);
    const navigate = useNavigate();

    const fetchCategoryData = async () => {
        app.startLoader();
        await getAllCategories(null).then(async response => {
            setCategories(response);
            auth.setCategories(response);
        }).catch(e => {
            console.log(e.message);
        }).finally(() => {
            app.stopLoader();
        });
    };

    useEffect(() => {
        fetchCategoryData();
    }, []);

    const handleCategoryChange = (event) => {
        const categoryId = event.target.value;
        if (categoryId) {
            navigate(`/categories/${categoryId}`);
        }
    };

    return (
        <div className="md:hidden w-full flex justify-between gap-4 items-center px-2">
            <div className="w-[20%]">
                <select onChange={handleCategoryChange}>
                    {Categories.map((category) => (
                        <option key={category.id} value={category.id}>
                            {category.name}
                        </option>
                    ))}
                </select>
            </div>
            <SearchBar />
        </div>
    );
};

export default NavbarSubsection;
